import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import bioimg from "../images/bioimg2.jpg"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <div className="container" style={{backgroundColor: 'rgba(0, 0, 0, 0.87)', borderRadius: '10px', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', padding: '25px', margin: '80px 0px 0px 0px'}}>
      <div className="row" style={{justifyContent: 'center'}}>
        <div className="col-md-7">
          <p style={{color: 'white', padding: '50px', fontSize: "16px"}}>Cassandra is a Ryerson University graduate with experience in media production and scheduling; directing and editing commercials and film; and sales strategies and marketing. She has substantial on-set experience in reality television as an Assistant Production Coordinator at Nikki Ray Media Agency, and strong leadership and communication abilities demonstrated through heading multiple media projects at Ten Four Pictures and Valiant Creative Agency. Cassandra is always looking for opportunities to learn new skills and to expand her industry knowledge in fulfilling work.</p>
        </div>
        <div className="col-md-5 align-self-center">
          <img src={bioimg} style={{padding: '30px', borderRadius: '50%'}} alt="Cassandra Sessa sitting down on set"/>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
